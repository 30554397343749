<template>
  <!-- Fixed max-width wrapper -->
  <div class="bg-gif">
    <div
      class="delimiter h-full flex items-center
      md:justify-start
      justify-center"
    >
      <div class="text-white paragraph-contact z-10">
        <h1
          class="font-bold uppercase transform -translate-x-1
          lg:text-7xl
          text-6xl"
        >
          {{ $t('contact.contact') }}
        </h1>
        <p class="text-xl mt-4 font-bold">
          <span class="flex items-center">
            <img
              class="contact-icon"
              src="@/assets/img/whatsapp.png"
              alt="Whatsapp"
            />&nbsp;
            Wa: +52 5630849290 </span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '../main'

export default {
  mounted() {
    store.setFooterContact(true)
  },
  unmounted() {
    store.clearFooterContact()
  },
}
</script>

<style scoped>
.contact-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.paragraph-contact {
  width: 303px;
}
.bg-gif {
  height: 100vh;
  position: relative;
}
.bg-gif::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/lo-que-la-gente-hace.gif);
  background-repeat: repeat-x;
  background-color: #bdbdbd;
  background-position: center center;
  background-size: cover;
  filter: brightness(0.8);
}
@media (min-width: 768px) {
  .bg-gif::before {
    filter: brightness(1);
    background-position: -172px center;
  }
  .paragraph-contact {
    margin-left: 58vw;
  }
}
@media (min-width: 1024px) {
  .bg-gif::before {
    background-position: calc(-501px + 13vw) center;
  }
  .paragraph-contact {
    margin-left: 60vw;
    width: 370px;
  }
  .bg-gif {
    min-height: 867px;
  }
}
@media (min-width: 1440px) {
  .bg-gif::before {
    background-position: -236px center;
  }
}
</style>
